export const downloads = [
    {
        category: 'bafa',
        files: [
            {
                name: 'FörderProfi Vollmacht',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Vollmacht.pdf',
            },
            {
                name: 'Bestätigung zu den förderfähigen Investitionskosten',
                link:
                    'https://www.foerder-profi.de/downloads/FoerderProfi_Bestaetigung-zu-den-foerderfaehigen-Investitionskosten.pdf',
            },
            {
                name: 'Hydraulischer Abgleich',
                link:
                    'https://files.vdzev.de/pdfs/hydraulischer-abgleich-einzelmassnahme/VDZ_Formular_HydrAbgleich_Einzelmassnahme_BEG.pdf',
            },
        ],
    },
    {
        category: 'kfw',
        files: [
            {
                name: 'FörderProfi BnD Formular',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_BnD-Formular.pdf',
            },
            {
                name: 'Vollmacht von der Verwaltung der WEG',
                link:
                    // tslint:disable-next-line:max-line-length
                    'https://www.kfw.de/PDF/Download-Center/F%C3%B6rderprogramme-%28Inlandsf%C3%B6rderung%29/PDF-Dokumente/Meine-KfW/6000005165_F_458_Vollmacht_WEG.pdf',
            },
            {
                name: 'Vollmacht durch einen bevollmächtigte Eigentümer',
                link:
                    // tslint:disable-next-line:max-line-length
                    'https://www.kfw.de/PDF/Download-Center/F%C3%B6rderprogramme-%28Inlandsf%C3%B6rderung%29/PDF-Dokumente/Meine-KfW/6000005169_F_458_Vollmacht_gemeinschaftliche_Vertretungsberechtigung.pdf',
            },
            {
                name: 'Checkliste Hilfestellung KfW BEG 2024',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Checkliste-Hilfestellung-KfW-BEG-2024.pdf',
            },
            {
                name: 'Hydraulischer Abgleich',
                link:
                    'https://files.vdzev.de/pdfs/hydraulischer-abgleich-einzelmassnahme/VDZ_Formular_HydrAbgleich_Einzelmassnahme_BEG.pdf',
            },
        ],
    },
    {
        category: 'general',
        files: [
            {
                name: 'Registrierungshilfe MaStR',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Registrierungshilfe-MaStR.pdf',
            },
            {
                name: 'Ergänzende Hinweise zur FörderProfi Vollmacht',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Ergänzende-Hinweise-zur-Vollmacht.pdf',
            },
            {
                name: 'Einwilligung des Eigentümers',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Einwilligung-des-Eigentuemers.pdf',
            },
            {
                name: 'Checkliste Hilfestellung BAFA-Anträge',
                link: 'https://www.foerder-profi.de/downloads/FoerderProfi_Checkliste-Hilfestellung-BAFA-Antraege.pdf',
            },
        ],
    },
];
