// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { links } from './common';

export const environment = {
    production: true,
    disableSubmit: false,
    instana: true,
    app: {
        logo: '../assets/img/brand-logo.svg',
        // @translate subc.app.title
        pageTitle: 'subc.app.title',
        // @translate subc.app.description
        pageDescription: 'subc.app.description',
        landingGroup: 'LandingPage',
        dropoutLink: 'https://foerderprofi-integration.viessmann.com',
        quest: 'SubsidyCalculator',
        hideNonRelevantKfwComponents: true,
    },
    captcha: {
        use: true,
        // siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // v2 captcha sitekey
        siteKey: '6Ldho7MZAAAAANT_KRBBT3phN1h73mt-jrmzD6xO',
    },
    cookie: {
        context: 'subc.cookie',
        domains: ['ev4-cdn-foerderprofi.azureedge.net', 'foerderprofi-integration.viessmann.com', 'localhost'],
    },
    configit: {
        url: 'https://api-integration.viessmann.com/backend/foerderprofi/v2',
        languages: ['de'],
        salesAreaId: '0500/01/01',
        salesAreaName: 'Germany',
    },
    http: {
        company: 'https://api-integration.viessmann.com/company/v3',
        translations:
            // tslint:disable-next-line:max-line-length
            'https://api-integration.viessmann.com/translations/v2/phraseapp-proxy/projects/fc898cc601fef86b79eca227cac8b806/locales/',
        statusPage: 'https://api-integration.viessmann.com/status/v1/apps/xrxj2l14w66j/summary',
        voucher: 'https://api-integration.viessmann.com/backend/foerderprofi/v2/voucher/validate/',
        vitoflow: 'https://hydraulischer-abgleich-integration.foerder-profi.de',
    },
    quest: {
        context: 'subc.quest',
        dropoutGroup: 'Dropout',
        recommendGroup: 'Recommend',
        orderPriceVariable: 'EligibilityCheck.Price',
    },
    file: {
        uploadUrl: 'https://api-integration.viessmann.com/backend/foerderprofi/v2/orders/{id}/attachments',
        downloadUrl: 'https://api-integration.viessmann.com/backend/foerderprofi/v2/', // base for relative path (attachments/...)
        // Used in file upload component
        integrationUser: 'integration@viessmann.com.rstage',
    },
    auth: {
        appId: 'foerder-profi',
        baseUrl: 'https://api-integration.viessmann.com',
        usersUrl: 'https://api-integration.viessmann.com/users/v1/users/me',
        loginRedirectUrl: 'https://foerderprofi-integration.viessmann.com/dashboard',
        registerBaseUrl: 'https://account-integration.viessmann.com',
    },
    analytics: {
        // to replace any user related ids from paths
        anonymizeParams: '^d+$',
    },
    menu: {
        // displayed in header navigation, if group just contains one element then link is directly shown,
        // otherwise the group identifier with sub menu is displayed
        top: {
            groups: [links.application, links.hydrobalance, links.downloads],
        },
        right: {
            icon: 'menu',
            groups: [links.application, links.hydrobalance, links.downloads],
        },
        footer: {
            company: {
                groups: [links.types, links.subjects, links.services, links.social],
            },
            legal: links.legal,
            social: links.social,
        },
    },
    icons: {
        'subc:facebook': '../assets/icons/facebook.svg',
        'subc:twitter': '../assets/icons/twitter.svg',
        'subc:linkedIn': '../assets/icons/linkedIn.svg',
        'subc:instagram': '../assets/icons/instagram.svg',
        'subc:youtube': '../assets/icons/youtube.svg',
    },
    // dateformat used in dateadapter
    dateFormat: {
        parse: {
            dateInput: 'l',
        },
        display: {
            dateInput: 'DD.MM.YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'L',
            monthYearA11yLabel: 'MMMM YYYY',
        },
    },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
